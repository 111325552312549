import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import loginto from "../assets/images/loginto.png";
import Modelimg1 from "../assets/images/easily-create-intelligent-video-apps.png";
import Modelimg2 from "../assets/images/precise-video-analysis.png";

export class PopupModal extends Component {
  state = {
    // name: "",
    firstname:"",
    lastname:"",
    email: "",
    visitCount: 0,
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitHandler = (e) => {
    e.preventDefault();

    this.props.setUserDetails(this.state);
  };
  render() {
    const { show, handleShow, handleClose } = this.props;
    return (
      <div>
        <Modal show={show} onHide={handleClose} className="contactPopup">
          <div class="modelPop">
            {/* <div class="bannerModel">
            <img src={Modelimg1} alt="" class="img-responsive" /> 
            <img src={Modelimg2} alt="" class="img-responsive" /> 
        </div> */}
            <div class="modelForm">
              <Modal.Header closeButton>
                <div className="logoBg">
                  {/* <svg className="logoSvg" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3401.57 2288.92">
										<path fill="#4e4e4f" fill-rule="evenodd" class="cls-1" d="M1333.2,311.8,750.27,1097.93,167.39,312.39V1627.56H1333.2Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" fill-rule="evenodd" id="Shape_8_copy" data-name="Shape 8 copy" class="cls-1" d="M2189,312.39,1451,1627.56H2937.71Z" transform="translate(-167.39 -87.36)" />
										<rect fill="#4e4e4f" class="cls-2" x="2861.33" y="636.19" width="540.24" height="903.91" />
										<circle fill="#2993c5" class="cls-3" cx="3126.05" cy="270.02" r="270.02" />
										<path fill="#4e4e4f" class="cls-2" d="M443.75,2040.47C473.68,1999.59,509.45,1977,562,1977c48.92,0,87.61,22.64,105.13,64.25,29.2-41.61,69.35-64.25,121.91-64.25,68.63,0,123.38,42.35,123.38,119.73v267.19H829.21V2132.45c0-46-9.5-85.42-67.17-85.42-54.75,0-82.49,36.51-82.49,95.64v221.2H596.32v-235.8c0-45.26-6.56-81-62.05-81-58.4,0-86.14,41.62-86.14,100v216.82H364.91V1987.18h78.84Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M1165.77,2376.28c-116.08,0-185.43-84-185.43-196.38,0-118.26,76.65-203.68,182.51-203.68,110.23,0,178.85,81,178.85,211V2196H1063.57c2.18,75.92,39.42,119,103.66,119,46,0,73-20.43,89.79-68.62h79.58C1317.61,2328.83,1250.45,2376.28,1165.77,2376.28Zm-3.65-338c-54.76,0-89.07,35.78-97.1,102.21h193.46C1253.37,2077,1216.87,2038.27,1162.12,2038.27Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M1762.22,2363.87H1684.1V2312c-23.36,40.88-64.24,62.05-120.45,62.05-106.58,0-173.75-77.39-173.75-199.3,0-121.18,66.44-197.84,170.83-197.84,53.29,0,93.44,20.44,119.72,59.13v-192h81.77Zm-288.36-190.54c0,82.49,40.15,133.6,105.12,133.6s103.66-48.92,103.66-132.14c0-82.49-39.42-130.67-106.58-130.67C1513.28,2044.12,1473.86,2093,1473.86,2173.33Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M2017.74,2376.28c-116.08,0-185.43-84-185.43-196.38,0-118.26,76.65-203.68,182.51-203.68,110.23,0,178.85,81,178.85,211V2196H1915.54c2.18,75.92,39.42,119,103.66,119,46,0,73-20.43,89.79-68.62h79.58C2169.58,2328.83,2102.42,2376.28,2017.74,2376.28Zm-3.65-338c-54.76,0-89.07,35.78-97.1,102.21h193.46C2105.34,2077,2068.84,2038.27,2014.09,2038.27Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M2418.54,1975.49c89.06,0,154.76,32.86,154.76,113.89v191.26c0,21.9,4.39,27.75,19.72,27.75,5.84,0,8.76,0,19-1.46v58.4c-26.28,6.57-38,8-54,8-32.13,0-55.49-12.41-61.33-45.26-34.31,32.85-82.49,45.26-132.13,45.26-78.12,0-127-43.07-127-112.43,0-100,93.45-109.5,168.64-119,57.67-7.3,74.46-11,82.49-21.9,3.66-4.38,5.11-13.14,5.11-27,0-38.7-24.82-57-77.38-57-54,0-77.38,18.25-83.95,67.17h-78.85C2263,2010.54,2326.56,1975.49,2418.54,1975.49Zm-13.14,219.74c-38.69,5.84-82.49,13.14-82.49,62.78,0,35.77,24.82,56.22,67.89,56.22,34.31,0,66.43-10.95,85.41-29.94,17.52-17.52,16.79-42.34,16.79-65v-44.53C2466,2190.12,2435.33,2190.85,2405.4,2195.23Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M2770.43,2363.87h-99.29v-98.56h99.29Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M3027.41,1975.49c89.06,0,154.76,32.86,154.76,113.89v191.26c0,21.9,4.38,27.75,19.71,27.75,5.84,0,8.76,0,19-1.46v58.4c-26.28,6.57-38,8-54,8-32.12,0-55.48-12.41-61.32-45.26-34.31,32.85-82.5,45.26-132.14,45.26-78.11,0-127-43.07-127-112.43,0-100,93.44-109.5,168.64-119,57.66-7.3,74.46-11,82.49-21.9,3.65-4.38,5.11-13.14,5.11-27,0-38.7-24.82-57-77.38-57-54,0-77.39,18.25-84,67.17h-78.84C2871.91,2010.54,2935.42,1975.49,3027.41,1975.49Zm-13.14,219.74c-38.7,5.84-82.5,13.14-82.5,62.78,0,35.77,24.82,56.22,67.89,56.22,34.32,0,66.44-10.95,85.42-29.94,17.52-17.52,16.79-42.34,16.79-65v-44.53C3074.86,2190.12,3044.19,2190.85,3014.27,2195.23Z" transform="translate(-167.39 -87.36)" />
										<path fill="#4e4e4f" class="cls-2" d="M3357.39,1923.66h-83.22V1841.9h83.22Zm0,440.21h-83.22V1987.18h83.22Z" transform="translate(-167.39 -87.36)" />
                  </svg> */}
                  <img src={loginto} alt="" />
                </div>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>
                  Test drive the power of Mixie AI 2.0
                  {/* Help us to keep you updated */}
                </Modal.Title>
                <Form onSubmit={(e) => this.onSubmitHandler(e)}>
                  <Form.Group controlId="formBasicName">
                    {/* <Form.Label>Name</Form.Label> */}
                    <Form.Control
                      onChange={this.onChangeHandler}
                      name="firstname"
                      type="text"
                      placeholder="First Name"
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    {/* <Form.Label>Email</Form.Label> */}
                    <Form.Control
                      onChange={this.onChangeHandler}
                      name="lastname"
                      type="text"
                      placeholder="Last Name"
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    {/* <Form.Label>Email</Form.Label> */}
                    <Form.Control
                      onChange={this.onChangeHandler}
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </Modal.Body>
              {/*<Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>*/}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

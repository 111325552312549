import React from "react";
//import FourVideosPage from './FourVideosPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import block1 from "../assets/images/001.jpg";
import block2 from "../assets/images/002.jpg";
import block3 from "../assets/images/003.jpg";
import block4 from "../assets/images/004.jpg";
import education from "../assets/images/education.webp";
import News from "../assets/images/News.webp";
import media from "../assets/images/media.webp";
import Businesses from "../assets/images/Businesses.webp";
import Enterprise from "../assets/images/Enterprise.png";
import KeyFeatures from "../assets/images/KeyFeatures.jpg";
import quality from "../assets/images/quality.png";

import banner1 from "../assets/images/banner1.jpg";
import bannerMob1 from "../assets/images/mobbanner1.jpg";
import banner2 from "../assets/images/banner2.jpg";
import bannerMob2 from "../assets/images/mobbanner2.jpg";
import banner3 from "../assets/images/banner3.jpg";
import bannerMob3 from "../assets/images/mobbanner3.jpg";

import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { PopupModal } from "./PopupModal";
import { endPoint } from "../api";
import axios from "axios";

import FronteraIMLogo from "../assets/images/frontera-imlogo.svg";

class Home extends React.Component {
  state = {
    redirect: false,
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  isActiveHandler = (arg) => {
    localStorage.setItem("isActive", arg);
    this.props.history.push(`/${arg}`);
  };
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: false,
    };
  }

  onClickHandler = (arg) => {
    this.handleShow();
  };

  handleClose = () => {
    this.setState({ show: false });
    return this.props.history.push(`/`);
  };

  handleSubmit = (user) => {
    // this.setState({ show: false });
    // return this.props.history.push(`/upload`);
    if (user) {
      console.log("user", user);
      axios
        .post(`${endPoint}/user`, { ...user, visitCount: ++user.visitCount })
        .then((res) => {
          console.log("res", res);
          window.localStorage.setItem("user", JSON.stringify(user));
          this.setState({ show: false });
          this.props.history.push("/upload");
        })
        .catch((error) => {
          this.props.history.push("/upload");

          console.log(error);
        });
    }
  };

  handleShow = () => this.setState({ show: true });

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
    };

    return (
      <div className="Home">
        <PopupModal
          show={this.state.show}
          setUserDetails={this.handleSubmit}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
        />
        <div className="banner_blk">
          <Slider {...settings}>
            <div className="pos-r">
              <img src={banner1} alt="" className="pcBanner" />
              <img src={bannerMob1} alt="" className="mobBanner" />
              <div className="bannerContant">
                <div className="container">
                  <div className="bCont">
                    <img
                      src={FronteraIMLogo}
                      alt="frontera-im"
                      className="bLogo"
                    />
                    <div className="bcontTxt">
                      <strong>Build</strong> <span> intelligence</span>
                      <br />
                      <strong>into your video applications</strong>
                    </div>
                    <p>
                      <Link
                        href=""
                        onClick={() => this.onClickHandler("upload")}
                        // onClick={() => this.isActiveHandler("upload")}
                        className="btn btn-OrangeBorder py-1 px-2 py-md-2 px-md-4 m-0"
                      >
                        Test drive the power of Mixie AI 2.0
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-r">
              <img src={banner2} alt="" className="pcBanner" />
              <img src={bannerMob2} alt="" className="mobBanner" />
              <div className="bannerContant">
                <div className="container">
                  <div className="bCont">
                    <img
                      src={FronteraIMLogo}
                      alt="frontera-im"
                      className="bLogo"
                    />

                    <div className="bcontTxt">
                      <strong>Improved</strong> <span> targeting</span>
                      <br />
                      of <strong> Ad Content</strong>
                    </div>
                    <p>
                      <Link
                        href=""
                        onClick={() => this.onClickHandler("upload")}
                        className="btn btn-OrangeBorder py-1 px-2 py-md-2 px-md-4 m-0"
                      >
                        Test drive the power of Mixie AI 2.0
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-r">
              <img src={banner3} alt="" className="pcBanner" />
              <img src={bannerMob3} alt="" className="mobBanner" />
              <div className="bannerContant">
                <div className="container">
                  <div className="bCont">
                    <img
                      src={FronteraIMLogo}
                      alt="frontera-im"
                      className="bLogo"
                    />
                    <div className="bcontTxt">
                      <strong>Discover</strong> more information <br />
                      from your <strong>Security video feeds</strong>
                    </div>
                    <p>
                      <Link
                        href=""
                        onClick={() => this.onClickHandler("upload")}
                        className="btn btn-OrangeBorder py-1 px-2 py-md-2 px-md-4 m-0"
                      >
                        Test drive the power of Mixie AI 2.0
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div class="homepage">
          <div class="bgGray">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-12">
                  <div class="pTxt">
                    {/* Our Mixie.ai engine enables powerful content discovery and
                    engaging video experiences for a variety of applications.
                    Utilizing the latest AI image and video recognition engines,
                    Mixie.ai engine can be used in default mode where we have a
                    vast number of objects that can be recognized or we can
                    customize our engine to recognize specific objects based on
                    customer needs. With the number of trained objects
                    approaching in the tens of thousands the Mixie.ai engine
                    will accurately recognize and extract meta data from
                    videoframes to provide credible information.{" "} */}
                    Our improved MiXie Media AI 2.0 engine extracts a wealth of
                    information from your live and on-demand videos making
                    highly personalized recommendations, suggesting intuitive
                    decision making and increasing experiential customer
                    engagements. Mixie Media AI’s deep learning capabilities
                    make your applications smarter and more productive.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bgWhite">
            <div class="container">
              <div class="row d-flex align-items-center mb-5 mb-md-0">
                <div class="col-md-5 col-12 mb-3 mb-md-0">
                  <div class="img">
                    <img src={block1} alt="" />
                  </div>
                </div>
                <div class="col-md-7 col-12">
                  <h3>
                    Intuitive Content Recommendation
                    {/* Enhanced Content Recommendation */}
                  </h3>
                  <p class="tvTextDetails">
                    {/* Utilize the Mixie.ai engine to improve search results and
                    rediscover a wealth of information accumulated in current
                    video libraries. Extracted meta data helps viewers filter
                    search results to get to relevant information quickly. */}
                    Improve search results and rediscover a wealth of
                    information accumulated in video libraries. Extracted
                    metadata helps viewers filter search results to get to
                    relevant information quickly.
                  </p>
                </div>
              </div>
              <div class="row d-flex align-items-center flex-md-row-reverse mb-5 mb-md-0">
                <div class="col-md-5 col-12 mb-3 mb-md-0">
                  <div class="img">
                    <img src={block2} alt="" />
                  </div>
                </div>
                <div class="col-md-7 col-12">
                  <h3>
                    Intelligent Security Analysis
                    {/* Advanced Security */}
                  </h3>
                  <p class="tvTextDetails ">
                    {/* The Mixie.ai engine is a natural fit for security
                    applications where processing either live or on-demand video
                    of either large libraries or live video feeds generates
                    results that help identify targeted elements. Our objects,
                    faces or sentiments detection can be further trained to
                    discover other characteristics based on the client’s needs. */}
                    The MiXie Media AI engine is a natural fit for security
                    applications where processing either live or on-demand video
                    of either large libraries or live video feeds generates
                    results that help identify targeted elements. Our objects,
                    faces or sentiments detection can be further trained to
                    discover other characteristics based on client’s needs.
                  </p>
                </div>
              </div>
              <div class="row d-flex align-items-center mb-5 mb-md-0">
                <div class="col-md-5 col-12 mb-3 mb-md-0">
                  <div class="img">
                    <img src={block3} alt="" />
                  </div>
                </div>
                <div class="col-md-7 col-12">
                  <h3>
                    Smart Workflow Automation
                    {/* Workflow Automation */}
                  </h3>
                  <p class="tvTextDetails">
                    {/* Manage cost and time by enabling an automated filter for
                    inappropriate content or generate automated transcription
                    from videos. */}
                    Insert automated workflow monitoring of production lines to
                    identify defects and damage as an enhanced production
                    quality assurance process functioning at speeds beyond human
                    reaction times.
                  </p>
                </div>
              </div>
              <div class="row d-flex align-items-center flex-md-row-reverse mb-3 mb-md-0">
                <div class="col-md-5 col-12 mb-3 mb-md-0">
                  <div class="img">
                    <img src={block4} alt="" />
                  </div>
                </div>
                <div class="col-md-7 col-12">
                  <h3>
                    On-target Contextual Advertising
                    {/* Targeted Contextual Advertising */}
                  </h3>
                  <p class="tvTextDetails ">
                    {/* Identify appropriate locations in videos to insert
                    advertising content based on segments of videos matching
                    timeframe based labels and ads. */}
                    Serve advertising at the most appropriate times to increase
                    response actions from viewers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bgGray">
            <div class="container">
              <div class="row d-flex capabilities mb-3 mb-md-0">
                <div class="col-md-12 col-12">
                  <h3>Capabilities</h3>
                </div>
                <div class="col-md-4 ">
                  <h4>01 Object Recognition </h4>
                  <p>
                    Be it humans, objects or animals. Video AI can help you
                    automate object recognition workflows.
                  </p>
                </div>
                <div class="col-md-4 ">
                  <h4>02 Rich Metadata Extraction </h4>
                  <p>Uncover the sentiments, faces or celebrities or more. </p>
                </div>
                <div class="col-md-4 ">
                  <h4>03 Searchable Video Archives</h4>
                  <p>Use AI to build searchable video archives.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="container">
              <div class="row d-flex align-items-center  keyFeatures mb-3 mb-md-0">
                <div class="col-md-5 col-12 mb-3 mb-md-0">
                  <div class="img">
                    <img src={KeyFeatures} alt="" />
                  </div>
                </div>
                <div class="col-md-7 col-12">
                  <h3>
                    Key Features
                    {/* Targeted Contextual Advertising */}
                  </h3>
                  <ul>
                    <li>Analyze images and videos</li>
                    <li>Image comparison</li>
                    <li>Object detection</li>
                    <li>Video event detection</li>
                    <li>Text detection</li>
                    <li>Sentiments detection </li>
                    <li>Celebrity recognition</li>
                    <li>Video metadata extraction</li>
                    <li>Face detection</li>
                    <li>Speech to text extraction</li>
                    <li>Flag inappropriate content</li>
                    <li>Custom tagging workflows</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="bgGray">
            <div class="container">
              <div class="row d-flex align-items-center  mb-3 mb-md-0 idealSolution">
                <div class="col-md-12 col-12">
                  <h3>Ideal Solution for </h3>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/education.png"
                    src={quality}
                    alt="Production Workflow Quality Control Automation"
                  />
                  <p>Production Workflow Quality Control Automation</p>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/media.png"
                    src={media}
                    alt="Media & Entertainment"
                  />
                  <p>Media & Entertainment</p>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/education.png"
                    src={education}
                    alt="Education"
                  />

                  <p>Education</p>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/Enterprise.png"
                    src={Enterprise}
                    alt="Enterprise Broadcasts"
                  />
                  <p>Enterprise Broadcasts</p>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/News.png"
                    src={News}
                    alt="Security & Surveillance"
                  />
                  <p>Security & Surveillance</p>
                </div>
                <div class="col-md-4">
                  <img
                    // src="https://intellimedianetworks.com/wp-content/uploads/2021/05/Businesses.png"
                    src={Businesses}
                    alt="Corporate Training"
                  />
                  <p>Corporate Training</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FourVideosPage /> */}
      </div>
    );
  }
}

export default Home;

import React from 'react';
import FourVideosPage from './FourVideosPage';

class Example extends React.Component {

	constructor(props) {
		super(props);
		// this.state = {
		// 	isUserLoggedIn: false,
		// }
		// if(this.props.history.action === 'POP'){			
		// 	localStorage.setItem('isActive', "example");
		// }
	}
	

	render() {

		return (
			
			<div className="Example">	
			    <FourVideosPage />
			</div>
        );
	}
}

export default Example;

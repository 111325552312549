import React from 'react';

class pageNotFound extends React.Component {

	componentDidMount() {
		this.props.history.push('/')
	}
    
    
    render() {
	 return(
	    <div>
	    	<h3>Page Not Found</h3>
	    </div>

	 );
    }
}

export default pageNotFound;
import React from 'react';
import { MDBContainer, MDBRow } from 'mdbreact';
import { Link } from 'react-router-dom';
import videos from '../data/data.json';
import uuid from 'uuid';
import V1 from '../assets/upload/video_001.jpg';
import V2 from '../assets/upload/video_002.jpg';
import V3 from '../assets/upload/video_003.jpg';
import V4 from '../assets/upload/video_004.jpg';
import ClapprPlayer from './clappr-player';
const uploads = [V1, V2, V3, V4];

class FourVideosPage extends React.Component {

	createVideoTab() {

		return videos.map((video, index) => {
			if (index === 0) {
				return (
					<div className="topVideo" key={uuid.v4()}>
						<div className="d-flex flex-row row">
							<div className="col-sm-12 d-flex mb-2 flex-column">
								<div className="video">
									{/*<video src={video.details.link} controls autoPlay="autoPlay"></video>*/}
									<ClapprPlayer source={video.details.link} />
								</div>
							</div>
							<div className="col-sm-12">
								<div className="tvDetails d-flex flex-column ">
									<h3 className="">{video.details.title}</h3>
									<p className="tvTextDetails ">{video.details.description}</p>
									<div className="tagBlk">
										{video.details.tags.map(tag => <span key={uuid.v4()} className="badge badge-theam">{tag}</span>)}
									</div>
									<div className="playOption mt-3">
										<Link to={`/video/${video.id}`} className="btn btn-Blue py-2 px-4 m-0">
											{/* <svg height="15px"  className="analyze mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.711 492.711">
										<path fill="#fff" d="M430.86,289.307c-19.633,86.941-97.368,152.139-190.168,152.139c-75.429,0-140.851-43.111-173.299-105.916 c-6.605,1.875-13.417,3.199-20.604,3.199c-13.107,0-25.251-3.65-36.035-9.508c34.068,94.188,124.139,161.82,229.938,161.82 c121.892,0,222.962-89.688,241.395-206.494c-9.816,4.65-20.666,7.459-32.223,7.459C443.26,292.006,436.979,290.892,430.86,289.307z " />
										<path fill="#fff" d="M54.638,187.71c24.96-79.001,98.919-136.441,186.055-136.441c73.072,0,136.779,40.407,170.199,100.016 c11.432-6.892,24.669-11.105,38.974-11.105c3.939,0,7.714,0.576,11.497,1.158C421.967,58.901,338.011,1.67,240.692,1.67 C120.411,1.67,20.345,88.982,0,203.5c12.951-10.224,29.063-16.583,46.789-16.583C49.472,186.917,52.021,187.434,54.638,187.71z" />
										<path fill="#fff" d="M46.789,305.664c14.887,0,27.963-7.619,35.647-19.15h36.085h41.35l33.015,91.029c3.436,9.459,12.239,15.898,22.295,16.32 c0.341,0.016,0.678,0.016,1.033,0.016c9.641,0,18.469-5.617,22.507-14.451l78.528-170.861l12.305,19.587 c4.584,7.278,12.881,12.154,21.227,11.606l27.237-0.26l35.843-0.337c7.619,11.867,20.861,19.777,36.004,19.777 c23.67,0,42.846-19.196,42.846-42.851c0-23.67-19.176-42.845-42.846-42.845c-13.578,0-25.54,6.439-33.391,16.286l-37.584,0.356 l-14.707,0.145l-29.303-46.692c-4.845-7.699-13.628-12.153-22.602-11.557c-9.108,0.598-17.134,6.119-20.926,14.399l-72.459,157.626 l-18.324-50.551c-3.565-9.801-12.865-16.342-23.313-16.342h-59.428H80.696c-7.815-10.232-20.019-16.934-33.907-16.934 c-23.665,0-42.847,19.181-42.847,42.831C3.942,286.449,23.124,305.664,46.789,305.664z" />
									</svg> */}
											Analyze
									</Link>
									</div>

								</div>
							</div>
						</div>
					</div>
				)
			}
		})
	}
	// componentDidMount(){
	// 	var myVideo = document.getElementsByClassName("nocontrols")  ;  
	// 		myVideo.controls = false;
	// 		console.log(myVideo.controls = false);
	// }

	createVideoList() {
		return videos.map((video, index) => {


			if (index > -1) {
				return (
					<div className="d-flex flex-md-row flex-column align-items-stretch p-md-2 lvListing" key={uuid.v4()}>
						<div className="d-flex flex-column lvVideo">
							<div className="video">
								{/* <video src={video.details.link}  className="nocontrols"></video> */}
								<img src={uploads[index]} className="" alt="" />

								{/* <div className="d-flex analyzeHover justify-content-center align-items-center">
										<Link to={`/video/${video.id}`} className="btn btn-Blue py-2 px-4 m-0">
										Analyze
										</Link>
									</div> */}
							</div>
						</div>
						<div className="d-flex flex-column lvDetails">

							<h3 className="text-truncate">{video.details.title}</h3>
							<p className="lvTextDetails">{video.details.description}</p>
							<div className="tagBlk">
								{video.details.tags.map(tag => <span key={uuid.v4()} className="badge badge-theam">{tag}</span>)}
							</div>
							<div className="d-flex lvAction">
								<Link to={`/video/${video.id}`} className="btn btn-BlueBorder py-1 px-3 m-0">
									Analyze
										</Link>
							</div>
						</div>



					</div>
				)
			}
		})
	}

	render() {

		return (
			<div className="FourVideosPage">
				<MDBContainer>
					<div className="home pt-sm-5 pt-2">
						<div className="row">
							<div className="col-md-7 col-12">
								{this.createVideoTab()}
							</div>
							<div className="col-md-5 col-12">
								<div className="listVideo">
									<div className="d-flex flex-column">
										{this.createVideoList()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</MDBContainer>
			</div>
		);
	}
}

export default FourVideosPage;

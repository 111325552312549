import React, { Component } from 'react';
import Clappr from 'clappr';

export default class ClapprPlayer extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    let changed = (nextProps.source != this.props.source);
    console.log('shouldComponentUpdate nextProps nextState', nextProps, nextState)
    this.props = nextProps;
    this.state = nextState;
    if (changed) {
      this.change(nextProps.source);
    }
    return false;
  }


  componentDidMount() {
    console.log('this', this)
    this.change(this.props.source);
  }

  componentDidUpdate() {
    console.log('this', this)
    this.change(this.props.source);
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }
  
  destroyPlayer = () => {
    if (this.player) {
      this.player.destroy();
    }
    this.player = null;
  }

  change = (source) => {
  console.log('source', source)
    if (this.player) {
      this.destroyPlayer();
    }
    
    this.player = new Clappr.Player({
      parent: this.refs.player,
      source: source,
      autoPlay: true,
      width: '100%',
      height: '100%',
    });
  }
  
  render() {
    return (
      <div>
        <div style={{'height': 300}} ref="player" id="player"></div>
      </div>
    );
  }
}
import axios from "axios";
import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { endPoint } from "../api";
import "./navbar.css";
import { PopupModal } from "./PopupModal";

class header extends React.Component {
  state = {
    show: false,
    isActive: "",
  };

  isActiveHandler = (arg) => {
    localStorage.setItem("isActive", arg);
    this.props.history.push(`/${arg}`);
  };

  onClickHandler = (arg) => {
    //localStorage.setItem('isActive', arg)
    if (window.localStorage.getItem("user")) {
      return this.props.history.push(`/${arg}`);
    }
    this.handleShow();
  };

  handleClose = () => {
    this.setState({ show: false });
    return this.props.history.push(`/`);
  };
  handleClose1 = () => {
    this.setState({ show: false });
    //return this.props.history.push(`/`)
  };
  handleShow = () => this.setState({ show: true });

  setUserDetails = (user) => {
    // this.handleClose1();

    // this.props.history.push("/upload");

    if (user) {
      console.log("user", user);
      axios
        .post(`${endPoint}/user`, { ...user, visitCount: ++user.visitCount })
        .then((res) => {
          console.log("res", res);
          window.localStorage.setItem("user", JSON.stringify(user));
          this.handleClose1();
          this.props.history.push("/upload");
        })
        .catch((error) => {
          this.props.history.push("/upload");

          console.log(error);
        });
    }
  };

  updateUserDetails = (user) => {
    if (user) {
      axios
        .post(`${endPoint}/user/${user._id}`, {
          ...user,
          visitCount: ++user.visitCount,
        })
        .then((res) => {
          window.localStorage.setItem("user", JSON.stringify(res.data));
          this.handleClose();

          this.props.history.push("/upload");
          //this.setState({ videoUploaded: true });
        })
        .catch((error) => console.log(error));
    }
  };

  render() {
    return (
      <header className="header">
        <PopupModal
          show={this.state.show}
          setUserDetails={this.setUserDetails}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
        />

        <div className="container main-nav">
          <div className="nav-top">
            <div className="d-flex flex-row row justify-content-between">
              <div className="d-flex col-12  col-md-2">
                <a href="/" className="logo m-auto">
                  <svg
                    class="logoSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 528.574 271.096"
                    // style="color: #ff7600"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.144"
                        y1="1"
                        x2="0.974"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#ff6501"></stop>
                        <stop offset="1" stop-color="#fc0"></stop>
                      </linearGradient>
                    </defs>
                    <g
                      id="Group_5126"
                      data-name="Group 5126"
                      transform="translate(0.001)"
                    >
                      <g
                        id="Group_5049"
                        data-name="Group 5049"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_19044"
                          data-name="Path 19044"
                          d="M-476.723-120.286l-46.2,35.526-46.2-35.526L-594.8-140V3.746h25.669V-87.841l25.258,19.3,20.946,16.223,20.946-16.223,25.258-19.3V3.746h25.669V-140ZM-404.85,3.746V-140h-25.669V3.746Zm215.413,0V-140h-25.669V3.746Zm59.347-118.077H-80.6L-66.226-140H-130.09A38.737,38.737,0,0,0-168.7-101.189v66.123A38.737,38.737,0,0,0-130.09,3.746h62.837L-80.6-21.923h-49.49a13.235,13.235,0,0,1-13.142-13.142V-62.172h45.177V-87.841h-45.177v-13.348A13.235,13.235,0,0,1-130.09-114.331Z"
                          transform="translate(594.8 171.83)"
                          fill="#444"
                        ></path>
                        <g
                          id="Group_5048"
                          data-name="Group 5048"
                          transform="translate(213.463 31.687)"
                        >
                          <path
                            id="Path_19045"
                            data-name="Path 19045"
                            d="M470.054-293.563C461.635-281.755,448.9-261.836,448.8-260.4c-.1,1.848,3.7,8.625,18.071,32.343l8.009,13.142h23c12.629,0,23-.205,23-.513s-2.362-4.312-5.236-9.035c-2.978-4.62-6.058-9.857-7.085-11.5-2.054-3.491-16.839-27.62-22.589-36.963-2.053-3.388-6.058-9.754-8.727-14.169C474.469-291.407,472-295,471.7-295A3.2,3.2,0,0,0,470.054-293.563Z"
                            transform="translate(-385.449 358.935)"
                            fill="#444"
                          ></path>
                          <path
                            id="Path_19046"
                            data-name="Path 19046"
                            d="M388.872-356.522c4.723,7.906,24.95,40.865,28.133,45.9l2.978,4.826L432-318.327c6.571-6.879,13.04-13.553,14.375-14.888l2.259-2.362-6.571-10.473c-3.594-5.75-9.188-15.2-9.125-15.234-.007-.019.089.141-23.321.141H386.1Z"
                            transform="translate(-386.1 361.285)"
                            fill="#444"
                          ></path>
                        </g>
                        <path
                          id="Path_19047"
                          data-name="Path 19047"
                          d="M582.865-388.285c-4.415.616-21.151,5.134-30.289,8.111-11.808,4-37.169,16.839-50.208,25.566-18.482,12.321-29.981,22.075-49.387,41.481a445.5,445.5,0,0,0-70.641,91.689L377-212.4h49.9l3.491-7.5c15.5-32.651,36.039-65.918,56.266-90.765,20.432-25.156,43.432-46.1,66.739-60.579,7.906-4.928,25.669-13.861,31.316-15.607,2.054-.719,3.8-1.437,3.8-1.746C588.512-389,588.1-389,582.865-388.285Z"
                          transform="translate(-172.881 388.87)"
                          fill="url(#linear-gradient)"
                        ></path>
                      </g>
                      <path
                        id="Path_19089"
                        data-name="Path 19089"
                        d="M-158.85-82.71h33.3l-1.98-9h-29.25Zm16.47-28.98,10.08,23.4.18,2.61,8.55,19.17h12.06l-30.87-66.51-30.78,66.51h11.97l8.73-19.71.18-2.34Zm38.07-17.82v63h10.8v-63Z"
                        transform="translate(615.08 337.606)"
                        fill="#444"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>
              <div className="d-flex col-12 col-md d-none d-sm-block">
                <div className="logoutBtn ml-auto"></div>
              </div>
              <div className="d-flex col-12 col-md">
                <div className="hamurgar">
                  {/*<ul className="nav_blk">
										<li><Link onClick={() => this.isActiveHandler('home')} className={localStorage.getItem('isActive') === 'home' ? 'active' : '' }>Home</Link></li>
										<li><Link onClick={() => this.isActiveHandler('example')} className={localStorage.getItem('isActive') === 'example' ? 'active' : '' }>Example</Link></li>
										<li><Link onClick={() => this.onClickHandler('upload')} className={localStorage.getItem('isActive') === 'upload' ? 'active' : '' }>Upload & Analyze</Link></li>
									</ul>*/}

                  {/* <NavLink
      exact
      activeClassName="navbar__link--active"
      className="navbar__link"
      to="/"
    >
      Home
    </NavLink>
    <NavLink
      activeClassName="navbar__link--active"
      className="navbar__link"
      to="/example"
    >
      Example
    </NavLink>
    <NavLink
      activeClassName="navbar__link--active"
      className="navbar__link"
      to="/upload"
      onClick={() => this.onClickHandler('upload')}
    >
      Upload & Analyze
    </NavLink> */}

                  <ul className="nav_blk">
                    <li>
                      <NavLink
                        exact
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/example"
                      >
                        Example
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/upload"
                        onClick={() => this.onClickHandler("upload")}
                      >
                        Upload & Analyze
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(header);

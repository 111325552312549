import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Router from './routes';
import Header from './components/header'
import Login from './components/Login';
import Main from './components/Main';

class App extends React.Component {
    render() {
        return (
           
            <div className="App">
                <Switch>
	                
	                <Route path="/" component={Main} />
                </Switch>
            </div> 
        );
    }
}

export default App;

import React from 'react';
import { Progress } from 'reactstrap';
import SpinnerPage from './SpinnerPage';

class   VideoLabelsTab extends React.Component {

	dataWithProgress = () => {
		if(this.props.segmentLabelAnnotations.length > 0) {
			return this.props.segmentLabelAnnotations.map((d, index) => (<div className="videoLabelsTabList" key={index}><div className="vtbStatus"><div className="vtbText">{`${d.entity.description}`}</div><div className="vtbPer">{`${Math.round(d.segments[0].confidence * 100)}%`}</div></div>
		      <Progress className="vtbProgress Progress" color="success" value={Math.round(d.segments[0].confidence * 100)} /></div>))
		}
	}

	render() {
		const { isLoading } = this.props;
		return (
			<div className="videoLabelsTab">
				<div className="videoLabelsTab_inner">		     
					{ isLoading 
						? (<div className="spinner"><SpinnerPage /></div>)
						: this.dataWithProgress()
					}
				</div>
			</div>
        );
	}
  
}

export default VideoLabelsTab;
import React from 'react';
import ReactJson from 'react-json-view';
import SpinnerPage from './SpinnerPage';
//import styles from './styles.css';

const ApiResponseTab = ({ isLoading, segmentLabelAnnotations }) => {


	return (
		<div className="ApiResponseTab">
		    { isLoading 
			  	? (<div style={{top:200}} className="spinner"><SpinnerPage /></div>)
			  	: <ReactJson src={segmentLabelAnnotations} />
		    }		
		</div>
    );
}

export default ApiResponseTab;

import React from "react";
import { withRouter } from "react-router-dom";
import "./navbar.css";
import Poweredby from "../assets/images/Poweredby.svg";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container main-nav">
          <div className="nav-top">
            <div className="d-flex flex-row row justify-content-between align-items-center">
              <div className="copyrightText col-6">
                <p>
                  Copyright © 2022 Intellimedia Networks. All Rights Reserved.
                </p>
              </div>
              <div className="d-flex col-12 col-md-6 d-flex justify-content-end">
                <a
                  href="https://intellimedianetworks.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Poweredby}
                    alt="powered_by"
                    style={{
                      objectFit: "cover",
                      height: "30px",
                      margin: "10px 0px",
                      float: "right",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);

import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import ApiResponseTab from './ApiResponseTab';
import VideoLabelsTab from './VideoLabelsTab';
import VideoTextTab from './VideoTextTab';

export default class Tab extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '3',
      des: false
    };
  }

  componentDidMount() {
    this.setState({ des: true})
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { fn, isLoading, annotations } = this.props; 
    return (
      <div className="Tab">
        <Nav tabs>
          
          <NavItem>
            {
              this.state.des 
              ? <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Video Text
            </NavLink>
            : <NavLink
              className={`active nav-link`}
              onClick={() => { this.toggle('3'); }}
            >
              Video Text
            </NavLink>
            }
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Video Labels
            </NavLink>
          </NavItem>
          
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              API 
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
                <ApiResponseTab isLoading={isLoading } segmentLabelAnnotations={annotations.segmentLabelAnnotations} />
          </TabPane>
          <TabPane tabId="2">
              <VideoLabelsTab isLoading={isLoading } segmentLabelAnnotations={annotations.segmentLabelAnnotations || []} />
          </TabPane>
          <TabPane tabId="3">
              <VideoTextTab textOccuredTime={fn} isLoading={isLoading} shotLabelAnnotations={annotations.shotLabelAnnotations || []}/>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
import React from "react";
import Clappr from 'clappr';
//import ClapprPlayer from './clappr-player';
import { endPoint } from '../api';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBProgress
} from "mdbreact";
import axios from "axios";
import Tab from "./Tab";
import "../assets/css/styles.css";

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      annotations: [],
      isLoading: false,
      baseUrl: `${endPoint}`,
      description: null,
      title: null
    };
  }

  textOccuredTime = time => {
    /*document.getElementById("myVideo").play();
    document.getElementById("myVideo").currentTime = `${time}`;*/
    
    this.player.seek(parseInt(time));

  };

  componentDidMount() {
    console.log('componentDidMount called', this.props.details)
    const { title, description, link, filename, dynamic } = this.props.details;
    this.setState({ title: title, description: description, filename: filename });
    //document.getElementsByClassName("active")[0].className = "";
    
    if (!dynamic) {
      const defaulDataUrl = `${this.state.baseUrl}/getanalyzedata/${
      this.props.details.filename
    }`;
      axios.get(defaulDataUrl).then(res => {
        this.setState({ annotations: res.data });
      });

    } else {
    
      this.onAnalyzeClickHandler(filename);
    }

    this.change(link);
  }

  change = (source) => {
  console.log('source', source)
    if (this.player) {
      this.destroyPlayer();
    }
    
    this.player = new Clappr.Player({
      parent: this.refs.player,
      source: source,
      autoPlay: true,
      width: '100%',
      height: '100%',
    });

  }


  onAnalyzeClickHandler = (filename) => {
    
    this.setState({ isLoading: true });
    const url = `${this.state.baseUrl}/upload/process/${filename}`;
    axios
      .get(url)
      .then(res => {
        this.setState({ annotations: res.data, isLoading: false });
      })
      .catch(err => console.log(err));
  };

  render() {
    const { link, title, description } = this.props.details || {};

    return (
      <MDBContainer>
        {/* <div className="pageTitle mt-4 mb-2 ">
						<h2>Video Intelligence Program</h2>
					</div> */}
        <div className="videoDetailPage pt-md-5 pt-2">
          <MDBRow>
            <div className="col-sm-7 mb-md-0 mb-3">
              <div className="video">
                {/*<video
                  id="myVideo"
                  src={link}
                  controls
                  autoPlay="autoPlay"
                  loop
                />*/}
                <div style={{'height': 300}} ref="player" id="player"></div>
                {/*<ClapprPlayer source={link} seekTime={this.state.seekTime} />*/}
              </div>
              <div className="vDetails">
                <h3>{this.state.title}</h3>
                <p>{this.state.description}</p>
              </div>

              {/*<div className="upload vUpload">
                <div className="uploadTitle mt-2 mb-0 ">
                  <h2>Upload your content and analyze</h2>
                </div>                
                <div className="dragText mb-3 border-bottom">
                    <p>Drag / Click to upload video</p>
                </div>
                <div className="btnrow">
                  <div className="fileselect">
                
                    <input type="file" name="video" onChange={this.handleFileChange} />
                  </div>
                  <div
                    className="btn btn-Blue py-2 px-4 m-0"
                    onClick={this.onAnalyzeClickHandler}
                  >
                    Analyze
                  </div>
                </div>
                <div className="newupload">
                  {this.state.loaded && (
                    <div className="nuStatus d-flex justify-content-between">
                      <span className="nuText">{this.state.filename.name}</span>
                      <b className="nuPer">{this.state.loaded}%</b>
                    </div>
                  )}

                  {this.state.loaded && (
                    <MDBProgress
                      material
                      value={this.state.loaded}
                      className="vtbProgress Progress progress"
                    >
                      {<span className="" />}
                    </MDBProgress>
                  )}
                  {this.state.fileUploadedSuccess && (
                    <span className="">{this.state.filesuccessmsg}</span>
                  )}
                </div>
              </div>*/}
            </div>

            <div className="col-sm-5 pb-2">
              {/* <h3>Results</h3> */}
              <Tab
                fn={this.textOccuredTime}
                isLoading={this.state.isLoading}
                annotations={this.state.annotations}
              />
            </div>
          </MDBRow>
        </div>
      </MDBContainer>
    );
  }
}

export default Upload;

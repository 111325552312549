import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const app = <BrowserRouter>
    <App />
</BrowserRouter>

ReactDOM.render(app, document.getElementById('root'));


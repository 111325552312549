import React from 'react';
import videos from '../data/data.json';
import Upload from './Upload';

class Video extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			videoDetails: null,
		}
	}

	componentDidMount() {
		const { match: { params: { id }}} = this.props;
		const videoDetails = videos.filter(video => video.id === id )[0];
		this.setState({ videoDetails: videoDetails })
		/*let username = window.localStorage.getItem('username')
        let isUserLoggedIn = window.localStorage.getItem('isLoggedIn')
        if (username && isUserLoggedIn) {
        	this.props.history.push(`/video/${id}`)
        } else {
        	this.props.history.push('/login')
        }*/
	}

	render() {

		const { details } = this.state.videoDetails || {};

		return (
			<div className="video">
			    {this.state.videoDetails !== null 
			    && <Upload details={details}/>
			    }
			    
			</div>
		);
	}
}

export default Video;
import React from 'react';
import Home from '../components/Home';
import Example from '../components/Example';
import Login from '../components/Login';
import pageNotFound from '../components/pageNotFound';
import { Route, Switch } from 'react-router-dom';
//import PrivateRoute from '../auth';
import Video from '../components/Video';
import Upload from '../components/Upload';
import UploadVideo from '../components/UploadVideo';

export default () => (
	<Switch>
        <Route exact path="/" component={Home} />
        {/* <Route path="/home" component={Home} /> */}
        <Route path="/example" component={Example} />
        <Route path="/upload" component={UploadVideo} />
        <Route path="/video/:id" component={Video} />
        <Route path="*" component={pageNotFound} />
    </Switch>
);

import React from "react";
import Router from "../routes";
import Footer from "./footer";
import Header from "./header";

class Main extends React.Component {
  render() {
    return (
      <div className="Main">
        <Header />
        <Router />
        <Footer />
      </div>
    );
  }
}

export default Main;

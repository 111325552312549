import React from 'react';
import Loader from 'react-loader-spinner';

class SpinnerPage extends React.Component {

    render() {
		return(
			<div className="spinner">
				<Loader 
				type="Oval"
				color="#00BFFF"
				height="75"	
				width="75"
				align="center"
				/>  
			</div> 
		);
    }
}

export default SpinnerPage;
import React from 'react';
import SpinnerPage from './SpinnerPage';
import uuid from 'uuid';

class VideoTextTab extends React.Component {

    secondsToMinutes(time){
        return Math.floor(time / 60)+':' 
               +(Math.floor(time % 60) < 10 ? '0' : '')
               +Math.floor(time % 60);
        
    }

  //   getTextAnnotations = () => {
	// 	if(this.props.textAnnotations.length > 0) {
	// 		return this.props.textAnnotations.map((d, index) => {
	// 			let startTimeOffset = d.segments[0].segment.startTimeOffset.hasOwnProperty('seconds') ? d.segments[0].segment.startTimeOffset.seconds : 0;
	// 			let endTimeOffset = d.segments[0].segment.endTimeOffset.hasOwnProperty('seconds') ? d.segments[0].segment.endTimeOffset.seconds : 0;
	// 			return  <li class='row' key={index}>
	// 				{<span class='col-7'>
	// 							{`${d.text}`}
	// 						</span>}
	// 				{<span class='col-2' >  {this.secondsToMinutes(startTimeOffset)}</span>} {<span class='col-2' >{this.secondsToMinutes(endTimeOffset)}</span>}
	// 				{<span class='col-1' ><a href='javascript:void(0)' onClick={() => this.props.textOccuredTime(startTimeOffset)}>
	// 					<i class='fa fa-play-circle'></i>
	// 				</a></span>}
	// 		            </li>
	// 		})
	// 	}
	// }

	getStartEndTimeOffset(segments) {
		return segments.map((segment, index) => {

			let startTimeOffset = segment.segment.startTimeOffset.hasOwnProperty('seconds') 
			    ? segment.segment.startTimeOffset.seconds : 0;
			let endTimeOffset = segment.segment.endTimeOffset.hasOwnProperty('seconds') 
			    ? segment.segment.endTimeOffset.seconds : 0;
			return <div className='d-flex flex-row timeRow' key={uuid.v4()}>				

				{
					<span className='vidTimeStart' > 
					    {this.secondsToMinutes(startTimeOffset)}
				    </span>
			    } 
				{
					<span className='vidTimeEnd' >
					    {this.secondsToMinutes(endTimeOffset)}
					</span>
			    }
				{
					<span className='vidTimePlay' >
					    <a className="playBtn" 
						    href='javascript:void(0)' 
						    onClick={() => this.props.textOccuredTime(startTimeOffset)}
					    >
					        <i className='playIcon'>
								<svg heigth="20px" className="play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 420">
									<path d="M210,21c104.216,0,189,84.784,189,189s-84.784,189-189,189S21,314.216,21,210S105.784,21,210,21 M210,0 C94.031,0,0,94.024,0,210s94.031,210,210,210s210-94.024,210-210S325.969,0,210,0L210,0z"/>
									<path d="M293.909,187.215l-111.818-73.591C162.792,100.926,147,109.445,147,132.545V287.42c0,23.1,15.813,31.647,35.147,18.998 L293.86,233.31C313.187,220.647,313.208,199.913,293.909,187.215z M279.006,217.868l-99.295,64.981 c-6.44,4.221-11.711,1.372-11.711-6.328V143.437c0-7.7,5.264-10.535,11.697-6.3l99.33,65.366 C285.46,206.731,285.453,213.647,279.006,217.868z"/>
								</svg>
							</i>
					    </a>
					 </span>}

			</div>
		})
	}

	getShotLabelAnnotations = () => {
		if (this.props.shotLabelAnnotations.length > 0) {
			return this.props.shotLabelAnnotations.map((d) => {
				return <li className='' key={uuid.v4()}>
					<span className='nameTxt' > 
					    {`${d.entity.description}`}
					</span> 
					<span className='vidTime' >
					    {this.getStartEndTimeOffset(d.segments)}
					</span>
				</li>
			})
		}
	}

	render() {
		const { isLoading } = this.props;
		return (
		    <div className="videoTextTab">		     
		        { isLoading 
				  	?   (<div className="spinner"> 
				  		    <SpinnerPage />
						  </div>)
						  
					:  <ul>
						<li className='liHead'>
							<span className='nameTxt'>
							Name
							</span>
							<span className="vidTime">
								<div className="d-flex flex-row"> 
									<span className='vidTimeStart' >Start</span>
									<span className='vidTimeEnd'>End </span>
								</div>
							</span>
						</li>
					        {this.getShotLabelAnnotations()}
					    </ul>
			    }
		    </div>
        );
	}
  
}

export default VideoTextTab;